import * as React from "react";

// types
import type { EmployeeType } from "../../types";

// components
import { Container, Divider, MantineTheme, Paper, Stack, Text, createStyles, useMantineTheme } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

// icons
import chevronLeft from '../../images/icons/cheveron-left.svg';
import chevronRight from '../../images/icons/cheveron-right.svg';

interface Props {
	employees: EmployeeType[];
}

const useStyles = createStyles((theme:MantineTheme) => ({
    employeeText:{
        paddingBottom: 0,
        [theme.fn.smallerThan('md')]:{
            fontSize: "calc(32px + 1vw)",
            marginTop: theme.spacing.xl,
            marginBottom: theme.spacing.xl,
            padding: theme.spacing.md
        }
    },

    employeeDescription:{
        [theme.fn.smallerThan('md')]:{
            fontSize: "calc(32px + 1vw)",
            lineHeight: 1.2,
            padding: 20
        }
    },

    employeeImg:{
        borderRadius: '50%',
        maxWidth: '126px',
        minWidth: '80px',
        [theme.fn.smallerThan('md')]:{
            maxWidth: '40%',
            minWidth: '35%',
        }
    },


}))

const ConsultantSection = ({ employees }: Props) => {
	const theme: MantineTheme = useMantineTheme();
    const { classes } = useStyles();
	return (
		<div>
			<Container size="md" >
				<Carousel
                    loop={true}
					mx="auto"
                    align="center"
					withIndicators
					styles={{
						indicator: {
							width: 10,
							height: 10,
							borderRadius: 5,
							background: "#CED4DA",
							transition: "background 950ms ease",
							"&[data-active]": {
								background: "#868E96",

							},
						},
                        control: {
                            boxShadow: "none",
                            border: "none",
                            backgroundColor: "transparent"
                          },
					}}
					nextControlIcon={<img src={chevronRight} alt={"bullet"} width={25} />}
					previousControlIcon={<img src={chevronLeft} alt={"bullet"} width={25} />}
				>
                    {
                        employees.map((employee: EmployeeType, index) => {
                            const image = getImage(employee.photo.gatsbyImageData);
                            return <Carousel.Slide key={index}>
                                <Paper p={30} m={40}>
                                    <Stack spacing={20}>
                                        <Text size="xl" color="gray.8" fw={700} align="center" className={classes.employeeText}>
                                            {employee.exampleTitle}
                                        </Text>
                                        <Stack align="center">
                                            <GatsbyImage
                                            image={image!} alt=""
                                            className={classes.employeeImg}/>
                                            <Text size="lg" color="gray.7" fw={700} align="center" className={classes.employeeText}>
                                                zoals {employee.zoals}
                                            </Text>
                                            <Divider my={10} size="xl" color="brand.4"  style={{width:50}}/>
                                            <Text size="sm" color="dimmed" align="center" className={classes.employeeDescription}>
                                                {employee.exampleDescription}
                                            </Text>
                                        </Stack>
                                    </Stack>
                                </Paper>
                            </Carousel.Slide>
                        })
                    }
				</Carousel>
			</Container>
		</div>
	);
};

export default ConsultantSection;
