import { graphql, useStaticQuery } from 'gatsby';
import type { ProjectType } from '../../types';

type GraphQLResult = {
	allContentfulProject: {
		nodes: ProjectType[];
	};
};

export const getRecentDistinctProjects = (): ProjectType[] => {
    const data = useStaticQuery<GraphQLResult>(graphql`
        query RecentDistinctProjects {
            allContentfulProject(limit: 3, sort: {fields: createdAt, order: DESC}) {
                distinct(field: client___id)
                nodes {
                    id
                    title
                    tags
                    summary
                    headerPicture {
                        gatsbyImageData(
                            width: 650,
                            height: 450
                        )
                    } 
                    slug                   
                }
            }
        }
    `);

    return data.allContentfulProject.nodes;
};
