import * as React from 'react';

// types
import type { PropsWithChildren, ReactNode } from 'react';

// components
import { List } from '@mantine/core';

interface Props extends PropsWithChildren{
    textClass?:any,
    icon?: ReactNode;
    items?: string[] | ReactNode[];
}

const ListSection = ({ icon=null, items=[], textClass}: Props) => {
    return (
        <List spacing="xs" size="lg" center icon={icon}>
            {items.map((item,i) => <List.Item className={textClass} key={i}>{item}</List.Item>)}
        </List>
    );
};

export default ListSection;
