import * as React from 'react';

// components
import { StaticImage } from 'gatsby-plugin-image';
import {
    Button,
    Container,
    Grid,
    Group,
    MantineTheme,
    Space,
    Stack,
    Text,
    ThemeIcon,
    Title,
    createStyles,
    useMantineTheme,
} from '@mantine/core';
import { TbUsers } from 'react-icons/tb';
import ListSection from '../sections/list-section';
import { getConsultantEmployees } from '../../services/employees/consultant-employees';
import { EmployeeType } from '../../types';
import ConsultantSection from '../sections/consultant-section';

// icons
import bulletIcon from '../../images/icons/bullet.svg';
import VectorSVG from '../../images/background-images/Vector.svg'

//apply style through mantine theme
const useStyles = createStyles((theme: MantineTheme) => ({

    dots: {
        backgroundColor: theme.colors.gray[1],
        backgroundImage: `url(${VectorSVG})`,
        backgroundPositionY: 'top',
        backgroundPositionX: 'right',
        backgroundRepeat: 'no-repeat',
        [theme.fn.smallerThan('md')]: {
            backgroundImage: 'none'
        }
    },

    groupIconTitle: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 7,
        },
    },

    icon: {
        [theme.fn.smallerThan('md')]: {
            transform: 'scale(2)',
            marginBottom: theme.spacing.xl
        }
    },

    titleSize: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(38px + 1vw)",
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            paddingTop: 20
        }
    },

    textSize: {
        [theme.fn.smallerThan('md')]: {
            textAlign: 'center',
            fontSize: "calc(32px + 1vw)",
        }
    },

    subTitleOne: {
        [theme.fn.smallerThan('md')]: {
            display:'none',
            margin: -50
        }
    },

    subTitleTwo: {
        [theme.fn.smallerThan('md')]: {

            fontSize: "calc(32px + 1vw)",
        }
    },

    alignContent: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            alignItems: 'center',
        }
    },

    buttonOne: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },

        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            minHeight: '100%',
            height: 105,
            marginTop: 60,
            marginBottom: 75,
            fontSize: "calc(30px + 1vw)",
            padding: 30,
            overflow: 'visible !important',
        }
    },

    buttonText: {
        [theme.fn.smallerThan('md')]: {
            overflow: 'visible !important'
        }
    },

    buttonTwo: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },

    },
    listClass: {
        [theme.fn.smallerThan('md')]: {
            alignSelf: 'center',
            textAlign: 'left',
            padding: 5,
        }
    },
    listFont: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(26px + 1vw)",
        }
    }
}))

const SoftwareConsultancy = () => {
    const { classes } = useStyles();
    const theme: MantineTheme = useMantineTheme();
    const employees: EmployeeType[] = getConsultantEmployees();
    const listItems: string[] = [
        'Experten met de juiste tech stack.',
        'Vlotte integratie in uw team.',
        'Duidelijke afspraken vanaf dag één.',
    ];
    return (
        <div className={classes.dots}
        >
            <Container py="5rem" size="lg">
                <Group mt={5} mb={7} className={classes.groupIconTitle}>
                    <ThemeIcon variant="filled" size={40} className={classes.icon}>
                        {<TbUsers size={25} />}
                    </ThemeIcon>
                    <Title size="h2" color="gray.8" className={classes.titleSize}>
                        Software consultancy
                    </Title>
                </Group>
                <Grid>
                    <Grid.Col offsetMd={0.5} offsetSm={2} sm={8} md={6} span={6}>
                        <Stack align="flex-start" className={classes.alignContent}>
                            <Space h="lg" />
                            <Title order={3} size="h3" my="sm">
                                <Text className={classes.subTitleOne} fw={800}>Onze experten staan voor u klaar</Text>
                            </Title>
                            <Text color="gray.7" weight={500} mb={17} sx={{ lineHeight: 1.6 }}
                                className={classes.textSize}
                            >
                                Of het nu gaat om analisten, ontwikkelaars, agile
                                coaches of technical leads, een Qmino expert aan
                                boord nemen is sowieso een slimme zet.
                                <br />
                                <br />
                                Onze consultants zijn full stack opgeleid in Java en
                                JavaScript en blinken uit in het schrijven van
                                toegankelijke software.
                            </Text>

                            <Button leftIcon={<TbUsers size={25} />} mt="xl"
                                component="a"
                                href="mailto:hello@qmino.com"
                                className={classes.buttonOne}>
                                Ik zoek een expert
                            </Button>

                            <Title order={3} size="h3">
                                <Text fw={800} className={classes.subTitleOne}>Uw voordeel</Text>
                            </Title>
                            <Text className={classes.listClass}>
                                <ListSection
                                    textClass={classes.listFont}
                                    icon={<img src={bulletIcon} alt={'bullet'} width={18} />}
                                    items={listItems}
                                ></ListSection>
                            </Text>
                            <br />
                            <Button leftIcon={<TbUsers size={25} />} mt="xl"
                                component="a"
                                href="mailto:hello@qmino.com"
                                className={classes.buttonTwo}>
                                Ik zoek een expert
                            </Button>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col offsetXs={0} offsetMd={0.5} offsetLg={0.5} xs={12} md={5}>
                        <ConsultantSection employees={employees}></ConsultantSection>
                    </Grid.Col>
                </Grid>
            </Container>
        </div>
    );


};

export default SoftwareConsultancy;
