import * as React from 'react';

// components
import {
  Container,
  Grid,
  MantineTheme,
  MediaQuery,
  Space,
  Text,
  Title,
  createStyles,
  useMantineTheme
} from '@mantine/core';

// illustrations
import applicationIdeaSVG from '../../images/illustrations/application-idea.svg';

const useStyles = createStyles((theme: MantineTheme) => ({

  subText: {
    [theme.fn.smallerThan('md')]: {
      lineHeight:1.3,
      textAlign: 'center',
      alignSelf: 'center',
      fontSize: "calc(40px + 1vw)",
    }
  },

  topIcon:{
    [theme.fn.smallerThan('md')]:{
        width: 700,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  },

}));

const DienstenHeader = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  return (
    <Container py="5rem" size="lg">
      <Grid>
        {/* <Grid.Col offset={2} offsetMd={0} > */}
        <MediaQuery largerThan={'md'} styles={{
          display: 'none',
        }}>

          <img
            src={applicationIdeaSVG}
            alt={'Webapplicaties en consultancy'}
            className={classes.topIcon}
          />

        </MediaQuery>
        {/* </Grid.Col> */}

        <Grid.Col offset={3} offsetSm={2.5} offsetMd={0} span={7}>
          <Title order={1} my="xl">
            <Text
              size={40}
              color="#444444"
              sx={{
                lineHeight: 1.3,

                [theme.fn.smallerThan('md')]: {
                  fontSize: "calc(64px + 1vw)",
                  lineHeight: 1,
                  textAlign: 'center'
                },
              }}>
              Webapplicaties en consultancy
              <br />
              op maat van uw vraag
            </Text>
          </Title>
          <Space h="xl" />

          <Text size={20} color="gray.7" mt="xl" className={classes.subText}>
            Zet onze experten in als deel van uw team of schakel onze Software Factory in voor de
            volledige ontwikkeling van uw webapplicatie.
          </Text>

        </Grid.Col>
        <Grid.Col  offset={0} offsetLg={0} offsetMd={-1.5} md={1} span={5} style={{
          position: 'relative',
          top: '120px',
          left: '50px',

        }}>
          <MediaQuery smallerThan={'md'} styles={{
            display: 'none',
          }}>

            <img
              src={applicationIdeaSVG}
              alt={'Webapplicaties en consultancy'}
            />

          </MediaQuery>

        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default DienstenHeader;
