import * as React from 'react';
import DienstenHeader from '../../components/diensten-page/diensten-header';
import CustomSoftware from '../../components/diensten-page/custom-software';
import SoftwareConsultancy from '../../components/diensten-page/software-consultancy';

import ProjectenHighlights from '../../components/diensten-page/project-highlights';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

//images
import Ellipse10SVG from '../../images/background-images/Ellipse10.svg'
import { MantineTheme, createStyles, useMantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
    root: {
        backgroundImage: `url(${Ellipse10SVG})`,
        backgroundPositionY: 'top',
        backgroundPositionX:'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '800px 1700px',
        Position:'fixed',
        [theme.fn.smallerThan('md')]:{
            BackgroundImage:'none',
            background:'none',
        }
    },
}));

const DienstenPage = () => {
    const theme: MantineTheme = useMantineTheme();
    const { classes } = useStyles();

    return (
        <Layout>
            <DienstenHeader/>
            <div className={classes.root}>
                <SoftwareConsultancy/>
                <CustomSoftware/>
            </div>
            <ProjectenHighlights/>
        </Layout>
    );
};

export const Head = () => (
    <SEO title='Diensten'/>
);

export default DienstenPage;