import * as React from 'react';
import type { ProjectType } from '../../types';
import {
    GatsbyImage,
    getImage
} from 'gatsby-plugin-image';
import { getRecentDistinctProjects } from '../../services/projects/recent-projects';
import { Link } from 'gatsby';
import { TbChevronRight } from 'react-icons/tb';
import {
    Card,
    Badge,
    Button,
    Text,
    Container,
    Title,
    MantineTheme,
    useMantineTheme,
    SimpleGrid,
    Space,
    Center,
    Flex,
    createStyles
} from "@mantine/core";
import TextDivider from "../ui/text-divider";

const useStyles = createStyles((theme) => ({
    project: {
        transition: 'box-shadow 150ms ease, transform 100ms ease',

        '&:hover': {
            boxShadow: `${theme.shadows.lg} !important`,
            transform: 'scale(1.02)',
        },

        [theme.fn.smallerThan('md')]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 50,
            marginLeft: 60,
            marginRight: 60,
            paddingRight: 0,
            paddingLeft: 0
        },
    },

    projectDescription: {
        [theme.fn.smallerThan('md')]: {
            padding: 20,
        },
        [theme.fn.largerThan('md')]: {
            height: "13rem",
            fontSize: "calc(16px + 1vw)",
        }
    },

    projectDescriptionBadge: {
        [theme.fn.largerThan('md')]: {
            marginTop: "auto",
        },

        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(18px + 1vw)",
            marginLeft: 85,
            marginRight: 'auto',
            marginTop: 20,
            padding: 20,
        }
    },

    mainTitle: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 7,
        },
    },

    projectTitle: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
            paddingBottom: 20,
            display: 'flex',
            lineHeight: 1.2,
            paddingLeft: 45,
            paddingRight: 45,
            marginLeft: 40,
            marginRight: 40
        },
    },

    contentAlign: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            fontSize: "calc(28px + 1vw)",
            textAlign: 'justify',
            paddingLeft: 85,
            paddingRight: 85,
            lineHeight: 1.2
        }
    },

    projectCenterAlign: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    },

    setImage: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            alignSelf: 'flex-center',
        }
    },

    buttonSize: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(28px + 1vw)",
            padding: 20,
            minHeight: '100%',
            height: 100,
        }
    }
}));

const ProjectenHighlights = () => {
    const projects: ProjectType[] = getRecentDistinctProjects();

    interface ProjectProps {
        project: ProjectType;
    }

    const { classes, theme } = useStyles();

    const Project = ({ project }: ProjectProps) => {
        const image = getImage(project.headerPicture.gatsbyImageData);
        return (
            <Link to={`/projecten/${project.slug}`} style={{ color: 'transparent' }}>
                <Card shadow="sm" p="lg" radius="md" withBorder className={classes.project}>
                    <Flex className={classes.setImage}>
                        <Card.Section p="lg" >
                            <GatsbyImage image={image!} alt="" />
                        </Card.Section>
                    </Flex>

                    <Flex className={classes.projectDescription} direction={"column"} align={"flex-start"} gap={"sm"} >
                        <Title order={1} size="h4" lineClamp={2}>
                            <Text className={classes.projectTitle}>{project.title}</Text>
                        </Title>
                        <Text size="sm" color="dimmed" lineClamp={4} className={classes.contentAlign}>
                            {project.summary}
                        </Text>
                        <Badge className={classes.projectDescriptionBadge} color={project.tags[0] == 'Consultancy' ? 'brand.9' : 'pink.8'} radius="sm"
                            sx={(theme) => ({
                                backgroundColor: project.tags[0] == 'Consultancy' ? theme.colors.brand[2] : theme.colors.pink[2],
                            })}
                        >
                            {project.tags[0]}
                        </Badge>
                    </Flex>
                </Card>
            </Link>
        );
    };

    return (
        <div
            style={{
                backgroundColor: theme.colors.gray[1],
            }}
        >
            <Container py="5rem" size="lg">

                <Title size="h2" color="gray.8" mb={15} className={classes.mainTitle}>
                    Wij maken werk van uw project</Title>
                <TextDivider />

                <Space h="xl" />
                <Space h="xl" />
                <SimpleGrid cols={3}
                    style={{ alignItems: "stretch" }}
                    breakpoints={[
                        { maxWidth: 'md', cols: 1, spacing: 'md' },
                    ]}
                    className={classes.projectCenterAlign}>
                    {projects.map((project: ProjectType, index) => {
                        return <Project key={index} project={project} />;
                    })}
                </SimpleGrid>
                <Space h="xl" />
                <Center p={30}>
                    <Button rightIcon={<TbChevronRight size={25} />} className={classes.buttonSize} mt="xl" component={Link} to="/projecten">
                        Meer over onze projecten
                    </Button>
                </Center>
            </Container>
        </div>
    );
};

export default ProjectenHighlights;
