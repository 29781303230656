import * as React from 'react';

// components
import {
    Button,
    Container,
    Grid,
    Group,
    MantineTheme,
    Space,
    Stack,
    Text,
    ThemeIcon,
    Title,
    createStyles,
    useMantineTheme
} from '@mantine/core';
import {
    TbBulb,
    TbCode
} from 'react-icons/tb';
import ListSection from '../sections/list-section';

// illustrations
import teamPresentationSVG from '../../images/illustrations/team-presentation.svg';

// icons
import bulletIcon from '../../images/icons/bullet.svg';
import TextDivider from '../ui/text-divider';

const useStyles = createStyles((theme: MantineTheme) => ({

    title: {
        marginTop: 100,
        fontSize: 64,
        lineHeight: '115%',

        [theme.fn.smallerThan('md')]: {
            maxHeight: 768,
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            gap: 7,
        },
    },

    titleSize: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(38px + 1vw)",
            textAlign: 'center',
            paddingTop: 20
        }
    },

    textSize: {
        [theme.fn.smallerThan('md')]: {
            textAlign: 'center',
            paddingRight: 40,
            fontSize: "calc(32px + 1vw)",
        }
    },

    subTitleOne: {
        [theme.fn.smallerThan('md')]: {
            paddingBottom: theme.spacing.xl,
            fontSize: "calc(32px + 1vw)",
            fontWeight: 800
        }
    },

    buttonOne: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(30px + 1vw)",
            overflow: 'visible !important',
            minHeight: '100%',
            height: 105,
            marginTop: 60,
            marginBottom: 70,
            padding:30,
        }
    },

    buttonTwo: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        }
    },

    alignContent: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
        }
    },

    listClass: {
        [theme.fn.smallerThan('md')]: {
            alignSelf: 'center',
            textAlign:'left'
        }
    },
    listFont: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(26px + 1vw)",
            lineHeight:1.2,
            paddingTop:20
        }
    },

    icon:{
        [theme.fn.smallerThan('md')]:{
            marginBottom:theme.spacing.xl,
            transform: 'scale(2)',
        }
    },

}))

const CustomSoftware = () => {
    const { classes } = useStyles();
    const theme: MantineTheme = useMantineTheme();
    const listItems: string[] = [
        'Een software partner die meedenkt met uw business.',
        'Uw bedrijfsproces vertaald in kwalitatieve eenvoud.',
        'Future proof applicaties, helemaal zoals u het wil.'
    ]
    return (
        <div>

            <Container py="5rem" size="lg">
                <Group mt={5} mb={7} className={classes.title}>
                    <ThemeIcon variant="filled" color="pink" size={40} className={classes.icon}>
                        {<TbCode size={25} />}
                    </ThemeIcon>
                    <Title size="h2" color="gray.8" className={classes.titleSize}>
                        Software op maat
                    </Title>

                </Group>
                <Grid>
                    <Grid.Col offsetMd={0} offsetXs={1} offset={3} xs={10} md={6} pl={60}>
                        <Stack align="flex-start" className={classes.alignContent}>
                            <Space h="xl" />
                            <Text
                                color="gray.7"
                                weight={500}
                                mb={17}
                                sx={{ lineHeight: 1.6 }}
                                className={classes.textSize}>
                                Het ervaren team van onze interne Software Factory werkt het gepaste antwoord uit
                                op uw vraag in de vorm van een webapplicatie op maat.
                            </Text>
                            <Text
                                color="gray.7"
                                weight={500}
                                mb={17}
                                fz='lg'
                                sx={{ lineHeight: 1.6 }}
                                className={classes.textSize}>
                                Dit maatwerk zorgt voor een vlotte integratie binnen uw bestaande systemen en een
                                optimale gebruikservaring.
                            </Text>

                            <Button color="pink" leftIcon={<TbBulb size={25} />} mt="xl"
                                component="a"
                                variant={'light'}
                                href="mailto:hello@qmino.com"
                                className={classes.buttonOne}>
                                Ik heb een project
                            </Button>

                            <Title order={3} size="h3" my="xl">
                                <Text fw={800} className={classes.subTitleOne}>
                                    Uw voordeel
                                </Text>
                            </Title>
                            <Text className={classes.listClass}>
                                <ListSection
                                    textClass={classes.listFont}
                                    icon={
                                        <img
                                            src={bulletIcon}
                                            alt="bullet"
                                            width={18}
                                        />
                                    }
                                    items={listItems}
                                >
                                </ListSection>
                            </Text>
                            <br />

                            <Button color="pink" leftIcon={<TbBulb size={25} />} mt="xl"
                                component="a"
                                variant={'light'}
                                href="mailto:hello@qmino.com"
                                className={classes.buttonTwo}>
                                Ik heb een project
                            </Button>

                        </Stack>
                    </Grid.Col>
                    <Grid.Col offset={4} offsetMd={1} span={5} >
                        <Space h="xl" />
                        <Space h="xl" />
                        <Space h="xl" />
                        <Stack align="flex-end">
                            <div>
                                <img

                                    src={teamPresentationSVG}
                                    alt="Software op maat"
                                    width={400}
                                />
                            </div>
                        </Stack>
                    </Grid.Col>
                </Grid>
            </Container>
        </div>
    );
};

export default CustomSoftware;
