import {
    graphql,
    useStaticQuery
} from 'gatsby';
import type { EmployeeType } from '../../types';

type GraphQLResult = {
    allContentfulEmployee: {
        nodes: EmployeeType[];
    };
};

export const getConsultantEmployees = (): EmployeeType[] => {
    const data = useStaticQuery<GraphQLResult>(graphql`
        query ConsultantEmployees {
            allContentfulEmployee(filter: {featured: {eq: true},exampleEnabled: {eq: true}}) {
                nodes {
                    id
                    name
                    photo {
                        gatsbyImageData(
                            width: 400,
                            height: 400
                        )
                    }
                    zoals
                    exampleTitle
                    exampleDescription
                    exampleEnabled
                }
            }
        }
    `);

    return data.allContentfulEmployee.nodes;
};
